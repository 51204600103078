import React from 'react';
// import Fade from 'react-reveal/Fade';

function Events() {
  return (
    <section className='events' id='events'>
      <div className='events__inner'>

        <div className='events__event'>

            <div className='events__left'>
              <div className='events__date'>
                23.08.2024
              </div>
              <div className='events__place'>
                EMOLAND FEST AFTERPARTY
              </div>
              <div className='events__city'>
                СПБ, Сердце
              </div>
            </div>

            <div className='events__right'>
              <div>
                <a href='https://vk.com/emolandfestspb' className='button' target='_blank' rel='noopener noreferrer'>
                  ИНФО
                </a>
              </div>
              <div>
                <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=66ba32c3d5105f418b8f3575&s=1&r=1&org=5d4423cfab2675575f4698f8&city=498817&category=592841f8515e35002dead914&tags=6571c7de533ae85cda00a778%2CEmo' className='button button--pink' target='_blank' rel='noopener noreferrer'>
                  БИЛЕТЫ
                </a>
              </div>
            </div>

        </div>
        {/*
        <div className='events__event'>

            <div className='events__left'>
              <div className='events__date'>
                03.08.2024
              </div>
              <div className='events__place'>
                EMOLAND FEST
              </div>
              <div className='events__city'>
                СПБ, Зелёная сцена
              </div>
            </div>

            <div className='events__right'>
              <div>
                <a href='https://vk.com/emolandfestspb' className='button' target='_blank' rel='noopener noreferrer'>
                  ИНФО
                </a>
              </div>
              <div>
                <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=665f6f070f087dc1f22c452c&s=1' className='button button--pink' target='_blank' rel='noopener noreferrer'>
                  БИЛЕТЫ
                </a>
              </div>
            </div>
        </div>
        */}
      </div>
      {/*<div className='events__two-buttons'>
        <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=665f73d2be09438c9c2925be&s=1' className='button button--pink button--padding' target='_blank' rel='noopener noreferrer'>
          БИЛЕТЫ НА 2 ГОРОДА
        </a>
      </div>*/}
    </section>
  )
}

export default Events;
