import { initializeApp } from 'firebase/app'

export const appName = 'emolandparty'

const config = {
  apiKey: 'AIzaSyAuaQpfodT8V4oOB0KFiFClJwdNo7RL2UQ',
  authDomain: `${appName}.firebaseapp.com`,
  databaseURL: `https://${appName}.firebaseio.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '361493820206'
}

initializeApp(config)
