import React from 'react';
import { ReactComponent as IGLogo } from '../svg/instagram.svg';
import { ReactComponent as VKLogo } from '../svg/vk.svg';
import { ReactComponent as SpotifyLogo } from '../svg/spotify.svg';
import { ReactComponent as TelegramLogo } from '../svg/telegram.svg';
import { ReactComponent as TikTokLogo } from '../svg/tiktok.svg';
import { ReactComponent as YouTubeLogo } from '../svg/youtube.svg';
// import Fade from 'react-reveal/Fade';

// <Fade bottom>
// </Fade>

function Footer() {
  return(
      <footer className='footer'>
        <div className='socials' id="contacts">
          <a href='https://t.me/emolandparty' className='social-link' target='_blank' rel='noopener noreferrer'>
            <TelegramLogo title='Telegram' />
          </a>
          <a href='https://vk.com/emo_land' className='social-link' target='_blank' rel='noopener noreferrer'>
            <VKLogo title='VK' />
          </a>
          <a href='https://www.instagram.com/_emoland_' className='social-link' target='_blank' rel='noopener noreferrer'>
            <IGLogo title='Instagram' />
          </a>
          <a href='https://open.spotify.com/user/qymmigorlyr720xn3fbf2ofmg?si=MYo2ZBBsRsy8MkWA4w1R8A' className='social-link' target='_blank' rel='noopener noreferrer'>
            <SpotifyLogo title='Spotify' />
          </a>
          <a href='https://www.tiktok.com/@_emoland_' className='social-link' target='_blank' rel='noopener noreferrer'>
            <TikTokLogo title='TikTok' />
          </a>
          <a href='https://www.youtube.com/@emolandparty' className='social-link' target='_blank' rel='noopener noreferrer'>
            <YouTubeLogo title='YouTube' />
          </a>
        </div>
        <div className='copyright'>
          <a href="https://indigoflow.space" target='_blank' rel='noopener noreferrer' title='Сайт разработан студией INDIGO FLOW'>
            INDIGO FLOW 2024
          </a>
        </div>
      </footer>
  )
}

export default Footer;
