import React from 'react';
// import Header from './header';
import Logo from './logo';
import Video from './video';
import Events from './events';
// import Merch from './merch';
import Info from './info';
import Footer from './footer';

function App() {
  return (
    <div>
      <div className='visually-hidden'>
        <h1>
          EMOLAND
        </h1>
        <p>
          Вечеринки от лузеров для лузеров.
        </p>
      </div>
      {/*<Header />*/}
      <main className='main'>
        {/*<Logo />*/}
        {/*<Video />*/}
        <div className='events__bracelet'>
          <img src='https://firebasestorage.googleapis.com/v0/b/emolandparty.appspot.com/o/v-3%2Femoland%20bracelet-2.png?alt=media&token=6dcc0960-825b-4738-a857-33bde446c35d' width='150' alt='EMOLAND BRACELET' />
        </div>
        <Events />
        <Info />
        {/*<Merch />*/}
      </main>
      <Footer />
    </div>
  );
}

export default App;
