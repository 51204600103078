import React from 'react';

function Info() {
  return (
    <section className='info'>
      <div className='info__inner'>

        <div className='info__photo'>
          <img src='https://firebasestorage.googleapis.com/v0/b/emolandparty.appspot.com/o/v2%2Fphoto-matt-kolya.png?alt=media&token=93719150-e804-4705-b349-e0fe9f88dc59' width='150' alt='MATT AND KOLYA' />
        </div>

        <div className='info__text'>
          <div className='info__text-one'>
            <div>
              ЧТО ТАКОЕ EMOLAND? ЭТО МЫ. ЭТО НАШИ БЛИЗКИЕ. ЭТО КОММЬЮНИТИ.
            </div>
            <div>
              МЫ ВКЛЮЧАЕМ СВОЮ ЛЮБИМУЮ МУЗЫКУ, И МУЗЫКУ НАШИХ ДРУЗЕЙ.
            </div>
          </div>
          <div>
            P.S. НЕТ, СТИГМАТЫ НЕ БУДЕТ, И НЕТ, 2007 НЕ ВЕРНЁМ.
          </div>
        </div>
      </div>
    </section>
  )
}

export default Info;
