import React, { Component } from 'react';
import { ReactComponent as Swipe } from '../svg/swipe.svg';
import { debounce, getVH, setVH } from '../utils';

class ScrollDownIcon extends Component {
  state = {
    scrollFromTop: 0
  }

  componentDidMount() {
    setVH();

    const setParameter = debounce(function() {
      setVH()
    }, 100);

    window.addEventListener('resize', setParameter);



    const self = this;

    const getParameter = debounce(function() {
      self.setState({
        scrollFromTop: getVH()
      })
    }, 250);

    window.addEventListener('scroll', getParameter);
  }

  render() {
    const elClass = this.state.scrollFromTop > 300 ? 'arrow arrow--dissapear' : 'arrow';
    return (
      <div className={elClass}>
        <Swipe />
      </div>
    )
  }
}

export default ScrollDownIcon;
